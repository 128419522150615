.h100 {
  height: 100%;
}

.w100 {
  width: 100%;
}

.fixed{
  position: fixed;
}

.flex-column{
  display: flex;
  flex-direction: column;
}

.App {
  background-color: rgb(18, 18, 18);
  color: white;
  justify-content: space-around;
  align-items: center;
  font-weight: 200;
}

p {
  letter-spacing: 7px;
  text-align: justify;
}

.normal-text {
  width: 50%;
  min-width: 380px;
  max-width: 700px;
  margin: auto;
  height: fit-content;
  transition: 2s all ease;
  opacity: 0;
  filter: blur(2px);
}

#options-container {
  width: 50%;
  min-width: 380px;
  max-width: 700px;
  margin: auto;
  height: 75%;
  display: flex;
  justify-content: space-evenly;
}

.options-text {
  display: flex;
  align-items: center;
  width: 100%;
  height: 33.3%;
  padding: 2rem;
  box-shadow: 0.15rem 0.15rem 0px 3px rgb(216, 216, 216);
  cursor: pointer;
  transition: 1s ease all;
  box-sizing: border-box;
  background-color: black;
}

.options-text:hover {
  background-color: rgba(255, 255, 255, 0.123);
}

.hidden {
  opacity: 0;
  filter: blur(2px);
  user-select: none;
}

.visible {
  opacity: 1;
  filter: blur(0px);
}
#logo *{
  box-sizing: border-box;
  width: 80vw;
  max-width: 800px;
  animation: 10s forwards fade-in-out;
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }
  10%, 60%{
    opacity: 1
  }
  100% {
    opacity: 0;
  }
  }


@import url(https://fonts.googleapis.com/css?family=MedievalSharp:regular);
#menu {
  margin: auto;
  justify-content: space-evenly;
  box-sizing: border-box;
  font-family: 'MedievalSharp';
  color: rgb(235, 235, 235);
}

#menu img{
  width: 60%;
  min-width: 350px;
  max-width: 800px;
  cursor: pointer;
  margin: auto;
  filter: drop-shadow(0px 0px 100px rgb(17, 128, 255));
}
#menu p {
  width: 60%;
  min-width: 350px;
  max-width: 800px;
  text-align: right;
  margin: auto;
  color: rgb(218, 218, 218);
  box-sizing: border-box;
  padding: 0.5rem;
}

.skip-button {
  padding: 0.5rem 4rem;
  position: fixed;
  bottom: 5rem;
  margin: auto;
  cursor: pointer;
  transition: 0.5s ease all;
  opacity: 0;
  animation: fade-in 2s ease forwards;
  transform: skew(-20deg) translate(-5%);
  border: none;
  outline: none;
  font-weight: 800;
  text-transform: uppercase;
  font-family: 'MedievalSharp';
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.42);
  background-color:  rgb(19, 19, 19);

  
}

.skip-button:hover {
   color: rgba(255, 255, 255, 0.776);
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  
}