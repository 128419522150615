#dust-container {
    position: fixed;
    pointer-events: none;
    z-index: 1000000;
    animation: rotate 3s linear infinite;
}

.dust-mote {
    position: absolute;
    background-color: rgba(210, 210, 210, 0.392);
    border-radius: 50%;
    animation: floatAnimation linear forwards;
}

@keyframes floatAnimation {
    0% {
        transform: translateY(100vh) rotate(0deg);
        opacity: 0.4;
    }
    80% {
        opacity: 0.9;
    }
    100% {
        transform: translateY(0vh) rotate(700deg);
        opacity: 0;
    }
    
}
